import React from 'react';

import { Link, Section } from '../../components';
import { Layout, SEO } from '../../layout';

interface Doc {
  slug: string;
  label: string;
  external?: boolean;
  subDocs?: Doc[];
}

interface LegalLinkProps {
  href: string;
  label: string;
  external?: boolean;
}

function LegalLink({ href, label, external }: LegalLinkProps) {
  return external ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  ) : (
    <Link to={`/legal/${href}`}>{label}</Link>
  );
}

function renderLegalDocs(docs: Doc[]) {
  return (
    <ul>
      {docs.map(({ slug, label, subDocs, external }: Doc) => (
        <li key={slug}>
          {subDocs?.length ? (
            label
          ) : (
            <LegalLink href={slug} label={label} external={external} />
          )}
          {subDocs?.length && renderLegalDocs(subDocs)}
        </li>
      ))}
    </ul>
  );
}

const Legal: React.FC = () => {
  const docs: Doc[] = [
    { slug: 'privacy', label: 'Privacy Policy' },
    {
      slug: 'terms',
      label: 'Client Terms & Conditions',
      subDocs: [
        {
          slug: 'select',
          label: 'Select Transactions API',
          subDocs: [
            {
              slug: 'terms-select-transactions-uk-europe',
              label: 'UK & Europe',
            },
            {
              slug: 'terms-select-transactions-north-america',
              label: 'North America',
            },
            {
              slug: 'terms-select-transactions-rest-of-world',
              label: 'Rest Of World',
            },
          ],
        },
        {
          slug: 'stream',
          label: 'Transaction Stream API',
          subDocs: [
            {
              slug: 'terms-transaction-stream-north-america',
              label: 'North America',
            },
            {
              slug: 'terms-transaction-stream-uk-europe',
              label: 'UK & Europe',
            },
          ],
        },
      ],
    },
    {
      slug: 'end-user-terms',
      label: 'End-user Terms',
      subDocs: [
        {
          slug: 'https://fidelapi.com/legal/end-user-terms-select-transactions-us.pdf',
          label: 'US users',
          external: true,
        },
        {
          slug: 'https://fidelapi.com/legal/end-user-terms-select-transactions-outside-us.pdf',
          label: 'Outside-US users',
          external: true,
        },
      ],
    },
    { slug: 'demo-app-terms', label: 'Demo App Terms' },
    { slug: 'merchant-terms', label: 'Merchant Terms' },
    { slug: 'merchant-privacy', label: 'Merchant Portal (CLO) Privacy Policy' },
    { slug: 'security', label: 'Security' },
    { slug: 'marketing-and-cookies', label: 'Marketing & Cookies' },
    { slug: 'offers-marketplace-terms', label: 'Offers Marketplace Terms' },
    { slug: 'merchant-partnership-terms', label: 'Merchant Partnership Terms' },
  ];

  return (
    <Layout>
      <SEO title="Legal" />
      <Section className="inner">
        <h1 className="text-left">Legal documents</h1>
        {renderLegalDocs(docs)}
      </Section>
    </Layout>
  );
};

export default Legal;
